<template>
    <div v-if="title && link" class="share_box">
        <label for="share_button" v-html="title_main ? title_main : 'Поделиться'"></label>
        <div id="shares_button">
            <a :href="'https://api.whatsapp.com/send?phone=&text='+encodeURI(title)+' '+encodeURI(link)" target="_blank" rel="nofollow">
                <div class="icons_shares whatsapp"></div>
            </a>
            <a :href="'https://vkontakte.ru/share.php?url='+encodeURI(link)+'&title='+encodeURI(title)+ (image ? '&image='+encodeURI(image) : '')" target="_blank" rel="nofollow">
                <div class="icons_shares vk"></div>
            </a>
            <a :href="'https://connect.ok.ru/offer?url='+encodeURI(link)+'&title='+encodeURI(title)+ (image ? '&imageUrl='+encodeURI(image) : '')" target="_blank" rel="nofollow">
                <div class="icons_shares ok"></div>
            </a>
        </div>
    </div>
    <div v-else>
        <PreLoadBox />
    </div>
</template>
<script>
export default {
    name: "ShareButtons",
    props: {
        title: String,
        link: String,
        image: String,
        title_main: String
    },
    data() {
        return {}
    },
    methods: {

    },
    mounted() {},

}
</script>
<style scoped>
.share_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

label {
    font-weight: 600;
    font-size: 14px;
}

#shares_button {
    display: flex;
    align-items: center;

}

#shares_button .icons_shares {
    transition: .2s ease;
    cursor: pointer;
}

#shares_button .icons_shares:hover {
    background-color: #28a745;
    transition: .3s ease;
}

#shares_button a {
    margin-right: 10px;

}

#shares_button a:last-child {
    margin-right: 0px;
}

.icons_shares {
    height: 40px;
    width: 40px;
    background-color: #4a76a8;

}

.left_menu a:hover .icons {
    background-color: #fff !important;
}

.whatsapp {
    mask-image: url('/images/icon/whatsapp.svg');
}

.vk {
    mask-image: url('/images/icon/vk.svg');
}

.ok {
    mask-image: url('/images/icon/okru.svg');
}
</style>