<template>
    <div>
        <div v-if="preLoad" class="default_box">
            <div v-if="item">
                <div class="default_header">
                    <h1 v-html="item.name"></h1>
                </div>
                <div class="default_box_content bg_white p15">
                    <imageLoader v-if="item.image" :src="item.image" :align="true"></imageLoader>
                    <p class="default_item_text" v-if="item.description" v-html="$htmContext(item.description)" itemprop="articleBody"></p>
                    <div class="default_link_description_box">
                        <p v-if="item.start_at !== null && $moment(item.start_at).format('DD.MM.YY') == $moment(item.end_at).format('DD.MM.YY')">
                            <img src="/images/icon/clock.svg" alt="icon">
                            <b>Дата:</b> {{$moment(item.start_at).format('DD.MM.YY, начало: HH:mm')}}</p>
                        <p v-else-if="item.start_at !== null && item.end_at !== null">
                            <img src="/images/icon/clock.svg" alt="icon">
                            <b>Дата:</b> {{$moment(item.start_at).format('DD.MM.YYYY')}} - {{ $moment(item.end_at).format('DD.MM.YYYY')}}, Начало: {{$moment(item.start_at).format('HH:mm')}}</p>
                        <p v-else-if="item.start_at !== null">
                            <img src="/images/icon/clock.svg" alt="icon">
                            <b>Дата:</b> {{$moment(item.start_at).format('DD.MM.YY HH:mm')}}</p>
                        <p v-if="item.address">
                            <img src="/images/icon/geolocation1.svg" alt="icon">
                            <b>Адрес: </b>{{item.address}}</p>
                        <p v-if="item.price">
                            <img src="/images/icon/ruble.svg" alt="icon">
                            <b>Стоимость: </b>{{item.price}}</p>
                        <p v-if="item.telephone">
                            <img src="/images/icon/call.svg" alt="icon">
                            <b>Телефон: </b><a :href="'tel:' + item.telephone">{{item.telephone}}</a></p>
                        <p v-if="item.url">
                            <img src="/images/icon/link.svg" alt="icon">
                            <b>Ссылка: </b><a :href="item.url" class="link_carryover" target="_blank" rel="nofollow">{{item.url}}</a></p>
                    </div>
                </div>
                <router-link v-if="item.organization" :to="{name:'OrganisationGet', params: {id:item.organization.id}}">
                    <div class="default_box_content bg_white p15 default_organisation_box">
                        <b-row class="p-0">
                            <b-col cols="12" sm="10">
                                <p>Организация в справочнике</p>
                                <div>{{item.organization.name}}</div>
                            </b-col>
                            <b-col cols="12" sm="2">
                                <img :src="item.organization.image" :alt="item.organization.name">
                            </b-col>
                        </b-row>
                    </div>
                </router-link>
                <div class="default_box_content bg_white p15">
                    <div class="default_item_footer" style="border-top: 0px !important; padding: 0;">
                        <ShareButtons :title="item.name" :link="'https://okaygorod.com' + this.$route.fullPath" :image="item.image ? item.image : null" />
                        <span v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:mm')" class="default_item_footer_date_news"></span>
                    </div>
                </div>
                <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
                <YouTube v-if="item.youtube" :item="item.youtube"></YouTube>
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
import imageLoader from "@/components/ImageLoader/imageLoader";
import ShareButtons from '@/components/Plagins/ShareButtons';
import YouTube from '@/components/YouTube/index';
import VkFrame from '@/components/Plagins/VkFrame/index';

export default {
    name: "Shares",
    components: {
        ShareButtons,
        YouTube,
        VkFrame,
        imageLoader,
    },
    metaInfo() {
        return {
            title: this.item && this.item.name ? `${this.item.name} - ` : null,
            meta: [
                {name: 'description', itemprop: 'description', content: this.item && this.item.description ? `${this.item.description}` : `Акции, скидки, каталоги продуктовых супермаркетов, магазинов электроники и бытовой техники, стройматериалов, одежды и обуви в городе ${this.$myCity.name}`},
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: this.item && this.item.name ? `${this.item.name} - ${this.$myCity.name}` : `Акции и скидки - ${this.$myCity.name}`},
                {property: 'og:description', content: this.item && this.item.description ? `${this.item.description}` : `Акции, скидки, каталоги продуктовых супермаркетов, магазинов электроники и бытовой техники, стройматериалов, одежды и обуви в городе ${this.$myCity.name}`},
                {property: 'og:image', content:this.item && this.item.image ? this.item.image : 'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'article' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            item: null,
        }
    },
    methods: {
        api() {
            this.preLoad = null;
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Shares.get';
            this.$http.get(url, {
                params: {
                    id: this.$route.params.id
                }
            }).then((response) => {
                this.item = response.data.response.item;
            }).catch(() => {
                this.$router.push({ name: 'SharesList' });
            }).finally(() => {
                this.preLoad = 'finish';
            })
        }, 
    },
    mounted() {
        this.api();
    },

}
</script>
<style scoped>
</style>